<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="warehouse.statistics_quantity_products_inventory"
      @click-clear-filter="clearFilter"
      @click-reload="filterChange"
      resource=""
      reloadable
    >
      <template #_="{ index }">
        <td>
          <span class="d-flex justify-content-center">
            {{ ++index }}
          </span>
        </td>
      </template>
      <template #quantity_inventory="{ item }">
        <td>
          <TMessageNumber :value="+item.quantity_inventory" />
        </td>
      </template>
      <template #product="{ item }">
        <td>
          <SCardProductItems
            v-if="item"
            :item="item.product"
            widthAuto
            resource="/warehouse/management/products"
          />
        </td>
      </template>
      <template #boxes="{ item }">
        <td>
          <div v-for="box in item.boxes" :key="box.product_id" class="d-flex">
            <TLink
              :content="box.box_id"
              class="ml-2 text-primary"
              :messageOptions="{ bold: true }"
              :to="lodash.getReferenceLink('box', box.box_id)"
            />
            <span class="px-1">-</span>
            <TMessage content="Quantity" bold>
              <template #suffix
                >:
                {{ box.quantity_inventory_in_boxes }}
              </template>
            </TMessage>
          </div>
          <TMessageNotFound v-if="!item.boxes.length" />
        </td>
      </template>

      <template #product-filter>
        <TInputText
          placeholder="Product Id"
          :value.sync="filter.product_id"
          @update:value="filterChange"
        />
      </template>
      <template #boxes-filter>
        <TInputText
          placeholder="SKU Id"
          :value.sync="filter.box_id"
          @update:value="filterChange"
        />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; max-width: 40px" },
        {
          key: "product",
          label: "Product",
          _style: "width: 450px; min-width: 450px",
        },
        {
          key: "boxes",
          label: "Box",
          _classes: "",
          _style: "width: 130px; min-width: 130px",
        },
        {
          key: "quantity_inventory",
          label: "Inventory number",
          _classes: "",
          _style: "width: 170px; min-width: 170px",
        },
      ],
      filter: {},
    };
  },
  created() {
    this.$store.dispatch(
      "warehouse.statistics_quantity_products_inventory.fetch.if-first-time"
    );
  },
  computed: {
    ...mapGetters({
      list: "warehouse.statistics_quantity_products_inventory.list",
      detail: "warehouse.statistics_quantity_products_inventory.detail",
      loading: "warehouse.statistics_quantity_products_inventory.loading",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      this.$store.dispatch(
        "warehouse.statistics_quantity_products_inventory.apply-query",
        this.lodash.pickBy(filter)
      );
    },
    clearFilter() {
      this.filter = {};
    },
  },
};
</script>
